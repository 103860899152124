import React, { useState } from "react";
import Button from ".";
import TranslationID from "data/locales/id/business.json";
import { PopupModal } from "react-calendly";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";

export type Variant =
  | "primary"
  | "secondary"
  | "secondary_transparant"
  | "secondary_white"
  | "secondary_gray"
  | "white"
  | "green"
  | "red"
  | "yellow"
  | "green_transparant"
  | "white_transparant";

const ButtonScheduleDemo: React.FC<{
  className?: string;
  id?: string;
  variant?: Variant;
  wrapperClass?: string;
}> = ({ className = "", id, variant, wrapperClass = "" }) => {
  const headingTranslationButton = TranslationID["Button"];
  const [isOpen, setIsOpen] = useState(false);

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  return (
    <div className={`relative ${wrapperClass ?? ""}`} id={id}>
      <Button
        className={`flex justify-center items-center gap-8 !rounded-8 !text-b1 ${className}`}
        onClick={() => {
          pushDataLayer({ event: id });
          // setIsOpen(!isOpen);
          showPopupFormSales("");
        }}
        variant={variant ?? "primary"}
        isFull={variant ? true : false}
      >
        {headingTranslationButton.contactSales}
      </Button>

      {/* <PopupModal
        url="https://calendly.com/gokampus/b2b-demo"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={
          typeof window !== "undefined"
            ? document.getElementById("__next")
            : null
        }
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: "00a2ff",
          textColor: "4d5055",
        }}
      /> */}
    </div>
  );
};

export default ButtonScheduleDemo;
