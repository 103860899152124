import Button from "components/Button";
import ButtonScheduleDemo from "components/Button/ButtonScheduleDemo";
import { CardNotFound } from "components/Card/CardNotFound";
import { Chip } from "components/Chip/Chip";
import { ContainerDesktop } from "components/Container";
import Drawer from "components/Drawer";
import { IconList } from "components/Icon/IconList";
import InstantSearchBox from "components/InstantSearchBox/InstantSearchBox";
import { Logo, LogoBusiness } from "components/Logo";
import { CartList } from "modules/Business/Selection/CartList";
import { useCartStore } from "modules/Business/Selection/cartStore";
import { HomeLogo } from "modules/Home/HomeLogo";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";

const PageHeaderBusiness: React.FC<{
  isTransparent?: boolean;
  useSearch?: boolean;
}> = ({ isTransparent, useSearch = false }) => {
  const router = useRouter();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const currentCart = useCartStore((state) => state.currentCart);
  const [menus, setMenus] = useState([
    {
      label: "Benefit",
      href: "",
      anchor: "#home-benefit",
    },
    {
      label: "Kursus",
      href: "",
      anchor: "#home-access",
    },
    {
      label: "Paket",
      href: "",
      anchor: "#home-packets",
    },
    // {
    //   label: "Pilih Kursus",
    //   href: "/pilih-kursus",
    // },
  ]);

  const isMobileView =
    typeof window !== "undefined" && window.screen.width < 993;

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // first prevent the default behavior
    e.preventDefault();
    // get the href and remove everything before the hash (#)
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    // get the element by id and use scrollIntoView
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const listAllowedHeaderBusiness = [
    "/",
    "/my-class",
    "/course/[courseSlug]",
    "/transactions/[transactionId]",
  ];
  return (
    <header
      className={
        "w-full z-50 h-72 sticky " +
        (isTransparent
          ? "bg-transparent top-0 "
          : "bg-white border-b border-neutral20_to_dark30 top-0 ")
      }
    >
      <ContainerDesktop
        className="h-full flex items-center justify-between"
        useAuto
      >
        <div className="flex justify-between items-center w-full gap-12">
          <div className="flex gap-32 items-center">
            <a href="/">
              <HomeLogo />
            </a>
            <div className="flex gap-32 sg-max:hidden">
              {menus.map((a) => (
                <Link
                  key={a.label}
                  //@ts-ignore
                  href={
                    a.href
                      ? a.href
                      : !listAllowedHeaderBusiness.includes(router.pathname)
                      ? "/"
                      : a.anchor
                  }
                  onClick={
                    a.href
                      ? undefined
                      : !listAllowedHeaderBusiness.includes(router.pathname)
                      ? () => router.push({ pathname: "/" })
                      : handleScroll
                  }
                  className="cursor-pointer font-medium text-black"
                >
                  {a.label}
                </Link>
              ))}
            </div>
          </div>

          <div className="m-auto mr-0 sg-max:hidden">
            {!isTransparent &&
              listAllowedHeaderBusiness.includes(router.pathname) && (
                <ButtonScheduleDemo className="!h-40" id="cta-header" />
              )}
          </div>

          <div className="flex flex-end gap-32 items-center">
            {useSearch && (
              <div className="lg-max:hidden">
                <InstantSearchBox
                  placeholder="Cari kursus disini"
                  position="right"
                  className="w-400"
                  theme="transparent"
                />
              </div>
            )}

            {!listAllowedHeaderBusiness.includes(router.pathname) && (
              <Button
                className={`rounded-8 !p-8 hover-unset relative !border-0  ${
                  currentCart.length > 0
                    ? "!bg-purple-05 hover:bg-purple-05"
                    : "!bg-white hover:bg-white"
                }`}
                onClick={() => setShowDrawer(!showDrawer)}
              >
                {/* <IconCart /> */}

                <div className="text-purple-50 font-medium sm-max:text-c1">
                  Kursus Pilihanmu
                </div>

                {/* @ts-ignore */}
                {currentCart.length > 0 && router.pathname !== "/cart/[id]" && (
                  <span className="min-w-[20px] min-h-20 px-4 text-c2 flex items-center justify-center absolute text-white rounded-18 bg-red-50 right-0 top-0 translate-x-[40%] -translate-y-[30%]">
                    {currentCart.length}
                  </span>
                )}
              </Button>
            )}
          </div>

          <div className="hidden sg-max:block">
            <div
              className="cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            >
              <IconList />
            </div>
          </div>
        </div>
      </ContainerDesktop>

      <Drawer
        isShow={showDrawer}
        isFullScreen={!isMobileView}
        title={
          <div className="flex gap-16">
            Kursus Pilihan{" "}
            <Chip className="bg-purple-05 text-purple-50">
              {/* @ts-ignore */}
              {router.pathname === "/cart/[id]" ? 0 : currentCart.length} Kursus
            </Chip>
          </div>
        }
        onClose={() => setShowDrawer(false)}
        childrenClass="flex flex-col justify-between h-[95%]"
        position={isMobileView ? "bottom" : "right"}
      >
        {/* @ts-ignore */}
        {router.pathname === "/cart/[id]" ? (
          <CardNotFound
            title="Kamu belum memilih kursus"
            message="Jelajahi kursus dan pilih kursus yang paling sesuai untuk perusahaanmu"
            className="!gap-16"
            useButton={[
              {
                href: "/pilih-kursus",
                label: "Pilih Kursus",
                variant: "primary",
                onClose: () => setShowDrawer(false),
              },
            ]}
          />
        ) : (
          <CartList
            className={
              currentCart.length
                ? "mb-16 !p-0 grid grid-cols-1 gap-16 max-h-[80vh] overflow-auto"
                : "h-[80vh]"
            }
            title=""
            onClose={() => setShowDrawer(false)}
            onClickButton={() => setShowDrawer(false)}
            wrapperList="py-20 !pr-20"
          />
        )}

        <div className="m-auto mb-0 w-full">
          <Button
            className="w-full"
            onClick={() => {
              setShowDrawer(false);
              //@ts-ignore
              router.replace("/cart");
            }}
            state={
              currentCart.length > 0 &&
              (router as any).pathname !== "/cart/[id]"
                ? "active"
                : "disabled"
            }
          >
            Lanjutkan
          </Button>
        </div>
      </Drawer>

      <Drawer
        isShow={showMenu}
        position="top"
        onClose={() => setShowMenu(false)}
      >
        {/* <div className="mb-48">
          <div className="flex flex-col gap-32">
            {menus.map((a) => (
              <Link
                key={a.label}
                //@ts-ignore
                href={
                  a.href
                    ? a.href
                    : !listAllowedHeaderBusiness.includes(router.pathname)
                    ? "/"
                    : a.anchor
                }
                onClick={
                  a.href
                    ? undefined
                    : !listAllowedHeaderBusiness.includes(router.pathname)
                    ? () => router.push({ pathname: "/" })
                    : (e) => {
                        setShowMenu(false);
                        handleScroll(e);
                      }
                }
                className="cursor-pointer font-medium text-black"
              >
                {a.label}
              </Link>
            ))}
          </div>
        </div> */}

        <ButtonScheduleDemo className="!h-40 w-full" id="cta-header" />
      </Drawer>
    </header>
  );
};

export default PageHeaderBusiness;
