import createStore from "zustand";

export type PopupStore = {
  // state
  isShow: boolean | false;
  currentCtaEvent: string;
  isShowYoutubeModal: boolean;

  // actions
  showPopupFormSales: (event: string) => void;
  closePopupFormSales: () => void;
  showPopupYoutube: () => void;
  closePopupYoutube: () =>void;
};

export const usePopupStoreFormSales = createStore<PopupStore>((set) => ({
  // state
  isShow: false,
  currentCtaEvent: '',
  isShowYoutubeModal: false,

  // actions
  showPopupFormSales(event) {
    set({ isShow: true, currentCtaEvent: event });
  },
  closePopupFormSales() {
    set({ isShow: false });
  },
  showPopupYoutube() {
    set({ isShowYoutubeModal: true });
  },
  closePopupYoutube(){
    set({ isShowYoutubeModal: false });
  }
}));
