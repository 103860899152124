export const IconList = ({ size = 24, color = "#551FC1" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Heroicons/Outline/menu">
        <path
          id="Icon"
          d="M4 6H20M4 12H20M4 18H20"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};