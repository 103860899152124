import { IconPlayCircle } from "components/Icon/IconPlayCircle";
import { IconStar } from "components/Icon/IconStar";
import { formatRating, numberWithCommas } from "lib/helper";
import React, { ReactNode } from "react";
import { Course } from "./interfaces";
import { IconTrash } from "components/Icon/IconTrash";
import { useRouter } from "next/router";
import { Image } from "components/Image/Image";

const CardCourseHorizontal: React.FC<Course> = ({ ...props }) => {
  const router = useRouter();
  return (
    <div className="flex justify-between items-center w-full cursor-pointer">
      <div className="flex flex-row w-[-webkit-fill-available] gap-8">
        {props?.image_cover_url ? (
          <div className="h-80 w-[140px] relative">
            <Image
              src={props?.image_cover_url}
              className="object-cover object-center rounded-8"
              width={140}
              height={80}
            />

            <div className="absolute rounded-8 w-[140px] h-80 top-0 flex justify-center items-center">
              <IconPlayCircle
                size={32}
                classes="m-auto"
                isBgCustomized
              />
            </div>
          </div>
        ) : (
          <div className="w-140 h-80 rounded-8 bg-neutral-20"></div>
        )}
        <div className="flex flex-col w-fit 2xl-max:max-w-[calc(100%-148px)] justify-center gap-4 text-b2">
          {/* @ts-ignore */}
          <div className="font-light line-clamp-1">
            {typeof props?.institution == "string"
              ? //  @ts-ignore
                props?.institution.split("||")[0] || ""
              : props.institution.name || ""}
          </div>
          <div
            className={`text-neutral-80 font-medium line-clamp-1 ${
              //  @ts-ignore
              router.pathname === "/cart" ? "w-full" : "max-w-[168px]"
            }`}
          >
            {props?.name}
          </div>

          <div className="flex gap-8 justify-start items-center font-light">
            {/* @ts-ignore */}
            {typeof props?.review_summary?.star_avg === "number" &&
            // @ts-ignore
            props?.review_summary?.star_avg !== 0 ? (
              <>
                <div className="flex items-center justify-center">
                  <IconStar size={12} fill color="#EFB443" />
                  <div className="ml-2">
                    {formatRating(
                      //  @ts-ignore
                      props?.review_summary?.star_avg.toFixed(1)
                    )}
                  </div>
                </div>
                |
              </>
            ) : (
              <></>
            )}
            {props?.total_learners !== 0 && (
              <div>{`${numberWithCommas(props?.total_learners)} pelajar`}</div>
            )}
          </div>
        </div>
      </div>

      {/* @ts-ignore */}
      {props.onDelete && router.pathname !== "/cart/[id]" && (
        //@ts-ignore
        <div onClick={props.onDelete} className="m-auto mr-0">
          <IconTrash color="#817E8C" />
        </div>
      )}
    </div>
  );
};

export default CardCourseHorizontal;
