import { CardNotFound } from "components/Card/CardNotFound";
import { useCartStore } from "./cartStore";
import CardCourseHorizontal from "components/Card/CardCourseHorizontal";
import { ReactNode } from "react";

export const CartList: React.FC<{
  className?: string;
  title?: string;
  onClose?(): void;
  onClickButton?(): void;
  beforeInput?: ReactNode;
  data?: any;
  wrapperList?: string;
}> = ({ className = "", title, onClose, onClickButton, beforeInput, data, wrapperList }) => {
  const { currentCart, updateCart } = useCartStore((state) => ({
    currentCart: state.currentCart,
    updateCart: state.updateCart,
  }));

  let allData = data ?? currentCart;

  return (
    <div className={`grid gap-16 ${className}`}>
      {title && <div className="text-h4 font-bold">{title}</div>}

      {allData.length > 0 ? (
        <div className={`bg-white rounded-16 mb-16 grid grid-cols-1 gap-16 h-fit ${wrapperList ?? "p-20"}`}>
          {beforeInput && <>{beforeInput}</>}
          {allData.map((course, index) => (
            //@ts-ignore
            <CardCourseHorizontal
              {...{
                ...course,
                index,
                onDelete: () => updateCart(course),
              }}
              key={Math.random()}
            />
          ))}
        </div>
      ) : (
        <div className="p-20 bg-white rounded-16 flex justify-center">
          <CardNotFound
            title="Kamu belum memilih kursus"
            message="Jelajahi kursus dan pilih kursus yang paling sesuai untuk perusahaanmu"
            className="!gap-16"
            useButton={[
              {
                href: "/pilih-kursus",
                label: "Pilih Kursus",
                variant: "primary",
                onClose: () => onClickButton(),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
